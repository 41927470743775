import { inboundOrders, linkInboundOrders, labMessageResultsPDF } from '../../queries/inbound';
import DataStore from '../shared/DataStore';
import { makeObservable, observable, computed, action } from 'mobx';
import { MobxApollo, Utility as Utils } from '../../../../utils';
export class InboundStore extends DataStore {
  constructor() {
    super({
      name: {
        value: '',
        error: undefined,
        placeHolder: 'Enter Inbound',
        label: 'LAB',
        rule: 'required',
      },
      userId: {
        value: '',
        error: undefined,
        label: 'LAB DIRECTOR',
        placeHolder: 'Enter Full Name',
        rule: 'required',
        objRef: 'poc.user.id',
        objRefOutput: 'poc',
      },
    }, 'labMessage', {
      all: inboundOrders
    });

    makeObservable(this, {
      data: computed,
      initLoad: action,
      attachedPDFList: observable.ref,
      labMessageList: observable.ref,
    });
  }
  attachedPDFList = undefined;
  labMessageList = [];

  initLoad(params) {
    //super.initLoad(params);
  }

  getAttachedPDFs = (id) => {
    MobxApollo.graphql({
      client: this.client,
      query: labMessageResultsPDF,
      fetchPolicy: 'network-only',
      variables: { id },
      onError: (err) => {
        console.error('onError=>', err);
        this.attachedPDFList = []
      },
      onFetch: (data) => {
        if (data) {
          this.attachedPDFList = data.labMessages.resultsPdfAttachmentsInfo || [];
        }
      },
    });
  };

  getLabMessages = async (params) => {
    const {status} = params
    console.log("getLabMessages ===> ", params)
    const result = await this.client.query({
      query: inboundOrders,
      variables: { filter: params.filter },
      });
    console.log("getLabMessages ===> ", result);
    if (result.data) {
      this.labMessageList = result.data.labMessages;
    }
    return this.labMessageList
  };

  resetDetails = () => {
    this.attachedPDFList = [];
  }

  detailsPageLoad(data) {
    this.gStatus = 2;
    this.progress = false;
    this.noloaderEvent = false;
  }

  linkResult =  async (params) => {
    this.progress = true;
    try {
      const {data} = await this.client.mutate({
        mutation: linkInboundOrders,
        variables: params
      });
      this.progress = false;
      let result = data.adminFixQuestResults;
      if(result.includes('not found for')) {
        Utils.toast(
          result,
          'error'
        );
        return false;
      } else {
        Utils.toast(
          'Result linked successfully',
          'success'
        );
        return true;
      }
      
    } catch (e) {
      this.progress = false;
      let errorMessage = Array.isArray(e) && e[0] && e[0].message ? e[0].message : 'Error while inking the results'
      Utils.toast(
        errorMessage,
        'error'
      );
      return false;
    }
  }
  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get useMeta() {
    return super.userMetaData;
  }

  get count() {
    return super.count();
  }
}

export default new InboundStore();
