import gql from 'graphql-tag';

const resultsPdfAttachmentsInfo = `
resultsPdfAttachmentsInfo {
  fileId
  fileName
  contentType
  key
  isUploaded
  entityId
  entity
}
`;

export const inboundOrders = gql`
query allLabMessages($first: Int,  $filter: LabMessageFilter){
  labMessages(first: $first, filter:$filter){
    id
    orderId
    dbOrderId
    ascensionNumber
    patientId
    patientFirstName
    patientLastName
    patientDOB
    patientAge
    patientGender
    brandName
    performingLab
    resultPDF
    labOrderId
    status
    messageFile
    fileName
    key
    resultsPdfAttachmentsInfo{
        fileId
        fileName
        contentType
        key
        isUploaded
        entityId
        entity
			}
    receivedAt
    createdAt
  }
}
`;

export const linkInboundOrders = gql`
mutation adminFixQuestResult1($fileName:  String!, $orderId: String) {
  adminFixQuestResults(fileName: $fileName, orderId:  $orderId)
}
`

export const labMessageResultsPDF = gql`
  query labMessageResultsPDF($id: ID!) {
    labMessage(id: $id) {
      orderId
      resultsPdfAttachmentsInfo {
        fileId
        fileName
        createdAt
      }
    }
  }
`;