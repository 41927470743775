import { makeObservable, computed, observable, action } from "mobx";
import { startCase, get, groupBy, mapValues } from "lodash";
import moment from 'moment';
import DataStore from "../shared/DataStore";
import {
  MobxApollo,
  FormHandler as FormHandle,
  Utility as Utils,
} from '../../../../utils';
import { ClaimClient as claimClient, GqlClient as client, getData } from '../../../../api/gqlApi';
import { NEW_ORDER, BULK_UPLOAD_MAX } from '../../../constants/order';
import {
  allOrders,
  orderResultsPDF,
  orderActivitiesQry,
  comment,
  ordersDownload,
  createOrder,
  updateOrder,
  order,
  createBulkOrder,
  orderAction,
  ordersCount,
  mdOrdersCount,
  getEligibleMdForAssignment,
  adminOrdersSmartSearch,
  editOrderCustomer,
  syncPluginOrder,
  syncOrderMarkersWithPanel,
  adminProcessCreatedStateOrder,
  adminProcessLabReleaseToMDReview,
  correctMarkers,
  summaryAllOrdersList,
  summaryAllOrdersCount
} from "../../queries/orders";

export class OrdersStore extends DataStore {
  constructor() {
    super(NEW_ORDER, 'order', {
      all: allOrders,
      create: createOrder,
      update: updateOrder,
      getOne: order,
      attach: orderAction.attachFileUploadToOrder,
      ordersCount: ordersCount,
      mdOrdersCount: mdOrdersCount,
    });
    makeObservable(this, {
      count: computed,
      loading: computed,
      progress: observable.ref,
      orderImportMeta: observable.ref,
      markers: observable.ref,
      data: computed,
      initLoad: action,
      setMarkers: action,
      comment: action,
      initImport: action,
      updateSelectedIds: action,
      generateReport: action,
      selectedIds: observable.ref,
      selectedorderIds: observable.ref,
      mdList: observable.ref,
      getAttachedPDFs: action,
      getOrderActivities: action,
      attachedPDFList: observable.ref,
      orderActivities: observable.ref,
      claim: action,
      resetDetails: action,
      summaryOrders: observable.ref
    });
  }

  progress = false;
  markers = [];
  attachedPDFList = undefined;
  orderActivities = undefined;
  selectedIds = [];
  selectedorderIds = [];
  listAction = false;
  ordersCount = [];
  mdOrders = {};
  mdList = [];
  summaryOrders = {};
  orderImportMetaData = [
    { label: "Panel", value: "panel.name" },
    { label: "Panel Code", value: "panel.prefix" },
    { label: "Unique ID", value: "orderId" },
    {
      label: "Created At",
      value: (row) => `${moment(row.createdAt).format('MM/DD/YY h:mm A')}`,
    },
    { label: "First Name", value: "patient.firstName" },
    { label: "Last Name", value: "patient.lastName" },
    { label: "Gender", value: "patient.gender" },
    { label: "Date of Birth", value: "patient.dob" },
    { label: "email", value: "patient.email" },
    {
      label: 'Address',
      value: (row) =>
        `${row.patient.address.addressLine1} ${row.patient.address.addressLine2}`,
    },
    { label: 'city', value: 'patient.address.city' },
    { label: 'State', value: 'patient.address.state' },
    { label: 'Zip Code', value: 'patient.address.zip' },
    { label: 'Phone', value: 'patient.phone' },
    { label: 'Status', value: 'status' },
    {
      label: 'MD Approval',
      value: (row) =>
        row.reviewAssignedMd
          ? `${row.reviewAssignedMd.firstName} ${row.reviewAssignedMd.lastName}`
          : '',
    },
    { label: 'NPI Approval', value: 'reviewAssignedMd.npi' },
    {
      label: 'MD Review',
      value: (row) =>
        row.releasedAssignedMd
          ? `${row.releasedAssignedMd.firstName} ${row.releasedAssignedMd.lastName}`
          : '',
    },
    { label: 'NPI Review', value: 'releasedAssignedMd.npi' },
    { label: 'Performing Lab', value: 'lab.name' },
    {
      label: 'Lab Director',
      value: (row) =>
        get(row, 'lab.poc.user')
          ? `${row.lab.poc.user.firstName} ${row.lab.poc.user.lastName}`
          : '',
    },
    {
      label: 'Lab Address',
      value: (row) =>
        get(row, 'lab.address')
          ? `${row.lab.address.addressLine1} ${row.lab.address.addressLine2} ${row.lab.address.city} ${row.lab.address.state} ${row.lab.address.zip}`
          : '',
    },
  ];
  orderImportMeta = [
    'panelPrefix',
    'orderId',
    'firstName',
    'lastName',
    'gender',
    'phone',
    'email',
    'dob',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zip',
    'labTestIds'
  ];

  initLoad(params) {
    super.initLoad(params);
  }

  updateSelectedIds = (value, orderId) => {
    const selected = [...this.selectedIds];
    const selectedOrders = [...this.selectedorderIds];

    this.selectedIds = selected.includes(value)
      ? selected.filter((s) => s !== value)
      : [...selected, value];

    this.selectedorderIds = selectedOrders.includes(orderId)
      ? selectedOrders.filter((s) => s !== orderId)
      : [...selectedOrders, orderId];

    this.listAction = this.selectedIds.length > 0;
  };

  getOrderActivities = (id) => {
    const pageNo = this.orderActivities && this.orderActivities.length || 0;
    const perPage = 20;
    const skip = perPage * pageNo;
    MobxApollo.graphql({
      client: this.client,
      query: orderActivitiesQry,
      fetchPolicy: 'network-only',
      variables: { id, first: perPage, skip },
      onError: (err) => {
        console.error('onError=>', err);
        this.orderActivities = []
      },
      onFetch: (data) => {
        if (data) {
          this.currTime = +new Date();
          if (!this.orderActivities || this.orderActivities.length === 0) {
            this.orderActivities = [data.order.activity] || [];
          } else {
            this.orderActivities[pageNo] = data.order.activity;
          }
        }
      },
    });
  };

  getAttachedPDFs = (id) => {
    MobxApollo.graphql({
      client: this.client,
      query: orderResultsPDF,
      fetchPolicy: 'network-only',
      variables: { id },
      onError: (err) => {
        console.error('onError=>', err);
        this.attachedPDFList = []
      },
      onFetch: (data) => {
        if (data) {
          this.attachedPDFList = data.order.resultsPdfAttachmentsInfo || [];
        }
      },
    });
  };

  claim = async ({ o, t }) => {
    this.progress = true;
    document.claimStatus = { jwtToken: t };
    try {
      const claimAction = await claimClient.mutate({
        mutation: orderAction.mdClaim,
        variables: { id: o },
      });
      this.progress = false;
      document.claimStatus = {
        status: 1,
        msgHead: 'You claimed this order.',
        message:
          'Sign-in now. You’ll have 1 hour to review and release this order.',
      };
      return claimAction;
    } catch (e) {
      this.progress = 0;
      if (e.toString().includes('This order is no longer available')) {
        document.claimStatus = {
          status: 0,
          message: 'This order is no longer available.',
          message2: 'Looks like another MD grabbed this one before you.',
        };
      } else {
        document.claimStatus = {
          status: 0,
          message: 'This is invalid request',
        };
      }
      return false;
    }
  };

  // details = () => getData(this.currentItem, `data.${this.keyMap.item}`) || {};

  getOne = (id, addonProps) => {
    this.entityDetails = {};
    this.gStatus = 1;
    let graphQlQuery = this.gqlRef.getOne;
    if (typeof this.gqlRef.getOne === 'function') {
      const roles = document.userMetaData || [];
      graphQlQuery = this.gqlRef.getOne(roles.includes('ADMIN'));
    }
    MobxApollo.graphql({
      client: this.client,
      query: graphQlQuery,
      fetchPolicy: 'network-only',
      variables: id ? { id } : null,
      onFetch: (res) => {
        if (res) {
          const data = res[this.keyMap.item] || null;
          const resultItem = { data: { ...res } };
          this.setFieldValue('currentItem', resultItem);
          this.gStatus = 2;
          if (id && data) {
            const markers = [];
            if (data.markers) {
              data.markers.forEach((m) => {
                markers.push({ id: m.id, markerRef: m.markerRef, reading: m.reading || '', name: m.name, resultCode: m.resultCode });
              });
            }
            this.markers = markers;
            this.entityDetails = data;
          }
          if (get(addonProps, 'actionFor') === 'edit-customer') {
            let setData = { ...data };
            this.CREATE_FRM = FormHandle.setFormData(this.CREATE_FRM, setData);
            this.validateForm('CREATE_FRM', true);
          }
          this.setFieldValue('isActionInProgress', false);
        }
        this.currTime = +new Date();
      },
      onError: (err) => {
        console.log('Error==>', err);
        this.setFieldValue('isActionInProgress', false);
      },
    });
  };

  resetMdOrders = () => this.mdOrders = {};

  setMdOrders = (value) => this.mdOrders = value;

  resetDetails = () => {
    this.entityDetails = {};
    this.currentItem = {};
    this.attachedPDFList = [];
    this.orderActivities = [];
  }

  executeQuery = (filter) => new Promise((res, rej) => {
    this.entityDetails = {};
    this.gStatus = 1;
    this.mdOrdersCount = MobxApollo.graphql({
      client: this.client,
      query: this.gqlRef.mdOrdersCount,
      fetchPolicy: 'network-only',
      variables: {
        filter: filter,
      },
      onError: (err) => {
        rej(err);
      },
      onFetch: (data) => {
        if (data) {
          res(data.ordersMeta.count);
        }
      }
    });
  });

  getMdOrdersCount = async (filter) => {
    try {
      return await this.executeQuery(filter);
    } catch (e) {
      console.log(e);
    }
  }

  getOrdersCount = (status, filter) => {
    this.entityDetails = {};
    this.gStatus = 1;
    this.ordersCount[status] = MobxApollo.graphql({
      client: this.client,
      query: this.gqlRef.ordersCount,
      fetchPolicy: 'network-only',
      variables: {
        first: this.params.perPage,
        skip: 0,
        orderBy: this.params.orderBy,
        filter: filter,
        userId: this.params.panelUserId
      },
      onError: (err) => {
        console.error('onError=>', err);
      },
      onFetch: (data) => {
        if (data) {
          this.result = data[this.keyMap.all];
          this.meta = data[`${this.keyMap.all}Meta`];
        }
      }
    });
  };

  setMarkers = (markers) => {
    this.markers = markers || [];
    this.currTime = +new Date();
  };

  orderAction = async (id, action, status, justification = '', param = null) => {
    this.progress = true;
    this.setFieldValue('isActionInProgress', true);
    try {
      let result = null;
      switch (action) {
        case 'assignMdToOrder':
          result = await this.client.mutate({
            mutation: orderAction[action],
            variables: { mdId: param, orderId: id, justification },
          });
          break;
        case 'mdOutreachAttempt':
          result = await this.client.mutate({
            mutation: orderAction[action],
            variables: { status: param, id: id, justification },
          });
          break;
        default:
          result = justification !== '' ?
            await this.client.mutate({
              mutation: orderAction[action],
              variables: { id, status, justification },
            }) :
            await this.client.mutate({
              mutation: orderAction[action],
              variables: { id, status },
            });
          break;
      }

      this.progress = false;
      try {
        let newStatus = '';
        if (action === 'setFlagByOrderId') {
          newStatus =
            status === 'FLAGGED' ? 'Flagged' : 'Flagged status resolved';
        } else {
          newStatus = startCase(
            getData(result.data[action], 'status')
              .split('_')[1]
              .toLowerCase()
          );
        }
        const msg = `${newStatus} successfully.`;
        Utils.toast(msg, 'success');
      } catch (e) {
        const mapping = {
          cancelOrder: 'Order cancelled successfully.',
          default: 'Updated successfully.',
        };
        Utils.toast(mapping[action] || mapping.default, 'success');
      }
      return true;
    } catch (e) {
      this.progress = 0;
      Utils.toast('Error while performing action', 'error', {}, e.message);
      return false;
    } finally {
      this.currTime = +new Date();
    }
  };

  validateImport = (data) => {
    let res = { status: false, formatted: [], errors: [] };
    data.forEach((d, j) => {
      if (j === 0 && d !== this.orderImportMeta) {
        res.status = false;
      } else {
        let row = {};
        this.orderImportMeta.forEach((e, i) => {
          row[e] = d[i];
        });
        res.formatted.push(row);
      }
    });
    if (res.formatted.length > BULK_UPLOAD_MAX) {
      Utils.toast(
        `Maximum number of uploads for bulk uploads at this time is ${BULK_UPLOAD_MAX}.`,
        'error'
      );
      this.resetBulkImport();
    }
    res.status =
      res.formatted.length > 25 ||
        res.formatted.length === 0 ||
        res.formatted.find((f) => f.error)
        ? false
        : true;
    return res;
  };

  resetBulkImport = () => {
    document.getElementById('ordersImporter').value = '';
  };

  generateReport = (data) => {
    let formatted = JSON.parse(JSON.stringify(groupBy(data, "panel.name")));
    Object.keys(formatted).forEach((panel) => {
      const metaData = [...this.orderImportMetaData];
      formatted[panel].forEach((o, oi) => {
        o.markers.forEach((om, omi) => {
          formatted[panel][oi][`marker${omi}`] = om.reading;
        });
      });
      formatted[panel][0].markers.forEach((m, i) => {
        metaData.push({ label: m.name, value: `marker${i}` });
      });
      const params = {
        fields: metaData,
        fileName: `orders-report_${panel}`,
        data: formatted[panel],
      };
      this.selectedIds = [];
      Utils.downloadCSV(params);
    });
    Utils.toast('Orders report(s) downloaded successfully.', 'success');
    this.noloaderEvent = false;
    this.progress = false;
  };

  downloadMarkers = () => {
    const data = this.details;
    const params = {
      fields: [
        { label: "markerId", value: (row) => `${data.orderId}_${row.name}` },
        { label: "reading", value: "reading" },
        { label: "createdAt", value: (row) => `${moment(data.createdAt).format('MM/DD/YY h:mm A')}` },
      ],
      fileName: `orders-report_markers_${data.orderId}`,
      data: data.markers,
    };
    Utils.downloadCSV(params);
  };

  approveCaseMeta = () => {
    let count = 0;
    let caseIds = [];

    this.result.forEach((e) => {
      if (e.status === 'MD_ASSIGNED' && !e.isFlagged) {
        count++;
        caseIds.push(e.orderId);
      }
    });
    return { count, caseIds };
  };

  getEligibleMdForAssignment = async (orderId) => {
    this.progress = true;
    this.mdList = [];
    MobxApollo.graphql({
      client: this.client,
      query: getEligibleMdForAssignment,
      variables: {
        orderId
      },
      onFetch: (res) => {
        if (res) {
          let data = [];
          let i = 0;
          res.getEligibleMdForAssignment.forEach((b) => {
            data[i++] = { key: b.id, text: `${b.firstName} ${b.lastName}`, value: b.id }
          });
          this.mdList = data;
        }
        this.progress = false;
      },
      onError: () => {
        Utils.toast('Error while loading MD list.', 'error');
        this.progress = false;
      },
    });
  };

  approveCase = async () => {
    const approveCaseMeta = this.approveCaseMeta();
    this.progress = true;
    MobxApollo.setLoading(true);
    const params = {
      approveAll: false,
      orderId:
        this.selectedorderIds.length <= 0
          ? approveCaseMeta.caseIds
          : this.selectedorderIds,
    };
    // console.log(params);
    try {
      await this.client.mutate({
        mutation: orderAction.mdApproveAll,
        variables: params,
      });
      this.progress = false;
      this.selectedIds = [];
      this.selectedorderIds = [];
      this.listAction = false;
      this.initLoad({ perPage: 50 });
      Utils.toast('Orders approved successfully.', 'success');
    } catch (e) {
      this.progress = 0;
      this.initLoad({ perPage: 50 });
      Utils.toast('Error while approving Orders.', 'error');
    }
  };

  confirmationMsg = () => {
    const approveCaseMeta = this.approveCaseMeta();
    const msg = this.listAction
      ? `Please confirm you would like to Approve the (${this.selectedorderIds.length}) selected orders?`
      : `Please confirm you would like to Approve all (${approveCaseMeta.count}) assigned orders`;
    return msg;
  };

  actionCTALabel = () => {
    return 'Approve Selected';
  };

  download = async (panel, status, specificIds = false) => {
    this.noloaderEvent = true;
    this.progress = true;
    const filters = {};
    if (panel) filters.panel = { id: panel };
    if (status) filters.status_in = status;
    if (specificIds) {
      filters.id_in =
        typeof specificIds === 'boolean' ? this.selectedIds : [specificIds];
    }
    MobxApollo.graphql({
      client: this.client,
      query: ordersDownload,
      variables: {
        orderBy: 'createdAt_DESC',
        filter: filters,
      },
      onFetch: (res) => {
        if (res && this.noloaderEvent) {
          this.generateReport(res.orders);
        }
      },
      onError: () => {
        Utils.toast('Error while downloading Orders report.', 'error');
        this.noloaderEvent = false;
        this.progress = false;
      },
    });
  };

  comment = async (params, showMsg = true) => {
    this.progress = true;
    this.noloaderEvent = true;
    try {
      await this.client.mutate({
        mutation: comment,
        variables: params,
        refetchQueries: [
          {
            query: this.gqlRef.getOne,
            variables: { id: params.orderId },
          },
        ],
      });
      this.progress = false;
      if (showMsg) {
        Utils.toast('Comment added successfully.', 'success');
      }
      return true;
    } catch (e) {
      this.progress = 0;
      if (showMsg) {
        Utils.toast('Error while posting comment.', 'error');
      }
      return false;
    } finally {
      this.noloaderEvent = false;
      this.currTime = +new Date();
    }
  };

  initImport = async (orders) => {
    const filteredOrders = { ok: [], ignored: [] };
    this.progress = true;
    const IMPORT_META = mapValues(NEW_ORDER, (f) => ({
      ...f,
      ...{ objRef: undefined },
    }));
    IMPORT_META.panelPrefix = { ...IMPORT_META.panelId };
    IMPORT_META.panelId = { ...IMPORT_META.panelId, ...{ rule: 'optional' } };
    let currOrder = FormHandle.prepareForm(IMPORT_META);
    try {
      orders.forEach((o) => {
        currOrder = FormHandle.setFormData(currOrder, o);
        currOrder = FormHandle.validateForm(currOrder, false, true);
        const additionalInfo = [];
        let orderMeta = { ...o };
        if (!currOrder.meta.isValid) {
          Object.keys(currOrder.fields).forEach((f) => {
            if (currOrder.fields[f].error) {
              additionalInfo.push(`${f}: ${currOrder.fields[f].error}`);
            }
          });
          orderMeta = { ...orderMeta, message: additionalInfo };
        }
        filteredOrders[currOrder.meta.isValid ? 'ok' : 'ignored'].push(
          orderMeta
        );
      });
      const res = await this.client.mutate({
        mutation: createBulkOrder,
        variables: { orders: filteredOrders.ok },
      });
      this.progress = false;
      const rec = get(res, 'data.createBulkOrder.orders') || [];
      const resData = {
        summary: {
          ...get(res, 'data.createBulkOrder.summary'),
          ...{ IGNORED: filteredOrders.ignored.length },
        },
        CREATED: rec.filter((o) => o.status === 'CREATED'),
        ERROR: rec.filter((o) => o.status === 'ERROR'),
        IGNORED: filteredOrders.ignored,
      };
      this.resetBulkImport();
      Utils.toast(
        'Order bulk upload operation completed, please review the generated result for details.',
        'success'
      );
      return resData;
    } catch (e) {
      this.progress = 0;
      console.log(e);
      Utils.toast('Error while importing orders', 'error');
      return false;
    } finally {
      this.currTime = +new Date();
    }
  };

  get loading() {
    return super.loading();
  }

  get data() {
    return super.data();
  }

  get details() {
    return super.details();
  }

  get count() {
    return super.count();
  }

  adminOrdersSmartSearch = (params) => {
    const { ref } = params;
    this.setLoading(true);
    this.result = [];
    try {
      if (ref && ref !== "") {
        this.listData = MobxApollo.graphql({
          client: this.client,
          query: adminOrdersSmartSearch,
          variables: {
            ref,
            first: this.params.perPage,
            skip: 0
          },
          onFetch: (res) => {
            if (get(res, 'adminOrdersSmartSearch[0]')) {
              this.result = get(res, 'adminOrdersSmartSearch');
              this.meta = get(res, 'adminOrdersSmartSearch').length;
              this.setLoading(false);
            }
          },
          onError: (e) => {
            this.setLoading(false);
          },
        });
      } else {
        this.initLoad({ filters: { ...this.params.filters } });
      }
    } catch (err) {
      this.setLoading(false);
    } finally {
      this.setLoading(false);
    }
  }
  editOrderCustomer = async (id) => {
    this.auStatus = 1;
    this.noloaderEvent = true;
    try {
      let formData = FormHandle.evaluateFormData(this.CREATE_FRM.fields);
      const currentOrder = { ...this.currentItem };
      const panelId = get(currentOrder, 'data.order.panel.id');
      const orderId = get(currentOrder, 'data.order.id');
      let variableObj = {
        id: orderId,
        panelId,
        gender: get(formData, 'patient.gender'),
        firstName: get(formData, 'patient.firstName'),
        lastName: get(formData, 'patient.lastName'),
        dob: get(formData, 'patient.dob'),
        phone: get(formData, 'patient.phone'),
        address: get(formData, 'patient.address'),
      }
      const claimAction = await client.mutate({
        mutation: editOrderCustomer,
        variables: variableObj,
      });
      Utils.toast(
        `${startCase(this.refKey)} ${id ? 'updated' : 'created'} successfully.`,
        'success'
      );
      this.auStatus = 2;
      this.noloaderEvent = false;
    } catch (e) {
      this.auStatus = 0;
      this.noloaderEvent = false;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while ${id ? 'updating' : 'creating'} ${this.refKey}.`,
        'error'
      );
    }
  }
  pluginSync = async (id) => {
    try {
      await client.mutate({
        mutation: syncPluginOrder,
        variables: {id},
      });
      Utils.toast(
        `Plugin Synced Successfully.`,
        'success'
      );
    } catch (e) {
      this.auStatus = 0;
      this.noloaderEvent = false;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while Plugin Sync.`,
        'error'
      );
    }
  }
  panelSync = async (id) => {
    try {
      await client.mutate({
        mutation: syncOrderMarkersWithPanel,
        variables: {orderId: id},
      });
      Utils.toast(
        `Order Markers Synced Successfully.`,
        'success'
      );
    } catch (e) {
      this.auStatus = 0;
      this.noloaderEvent = false;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while Order Marker Sync.`,
        'error'
      );
    }
  }
  adminProcessCreatedStateOrder = async (id) => {
    try {
      await client.mutate({
        mutation: adminProcessCreatedStateOrder,
        variables: {id},
      });
      Utils.toast(
        `Order updated Successfully.`,
        'success'
      );
    } catch (e) {
      this.auStatus = 0;
      this.noloaderEvent = false;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while updating Order.`,
        'error'
      );
    }
  }
  adminProcessLabReleaseToMDReview = async (id) => {
    try {
      await client.mutate({
        mutation: adminProcessLabReleaseToMDReview,
        variables: {id},
      });
      Utils.toast(
        `Order updated Successfully.`,
        'success'
      );
    } catch (e) {
      this.auStatus = 0;
      this.noloaderEvent = false;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while updating Order.`,
        'error'
      );
    }
  }
  correctMarkers = async (id, orderId) => {
    try {
      this.progress = true;
      const { data } = await client.mutate({
        mutation: correctMarkers,
        variables: { id, orderId },
        onFetch: (res) => {
        },
      });

      console.log('this.datathis.datathis.datathis.data', id);
      await this.getOne(id);

      Utils.toast(
        data.correctMarkers,
        'success'
      );
      this.progress = false;
    } catch (e) {
      this.progress = false;
      const error = e.message && e.message.replace(/GraphQL error:/, '');
      Utils.toast(
        error ? error : `Error while Order Marker Sync.`,
        'error'
      );
    } finally {
      this.currTime = +new Date();
    }
  }

  summaryOrderFetch = async (params, type) => {
    let fetchQuery;
    if(type === 'list') {
      fetchQuery = summaryAllOrdersList;
    }
    if(type === 'count') {
      fetchQuery = summaryAllOrdersCount;
    }
    return new Promise((resolve, reject) => {
      MobxApollo.graphql({
        client: this.client,
        fetchPolicy: 'network-only',
        query: fetchQuery,
        variables: params,
        onFetch: (res) => {
          if (res) {
            this.summaryOrders = res;
            resolve(res);
          }
        },
        onError: (error) => {
          Utils.toast('Error while downloading Orders Summary.', 'error');
          reject(error);
        },
      });
    });
  }
}
  

export default new OrdersStore();
