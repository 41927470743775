import { GENDER } from './shared';

const FILE_UPLOAD = {
  fileName: '',
  fileType: '',
  fileSize: '',
  id: '',
  base64String: '',
  tags: [],
  showLoader: false,
  confirmModal: false,
  objType: 'fileUpload',
};

export const COPY_PANEL = {
  copyTargetEnv: {
    values: [
      { key: 'DEV', text: 'DEV', value: 'DEV' },
      { key: 'DEMO', text: 'DEMO', value: 'DEMO' },
      { key: 'PREDEV', text: 'PREDEV', value: 'PREDEV' },
      { key: 'MASTER', text: 'MASTER', value: 'MASTER' }
    ],
    error: undefined,
    label: 'TARGET ENV',
    placeHolder: 'select',
    rule: 'required',
  },
  copyAuthToken: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Auth Token for TARGET ENV',
    label: 'Auth Token for TARGET ENV',
    rule: 'optional',
  },
  copyPanelName: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Panel Name',
    label: 'PANEL NAME',
    rule: 'required',
    objRef: 'name',
  },
  copyPanelPrefix: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Prefix',
    label: 'PANEL PREFIX',
    rule: 'required',
    objRef: 'prefix',
  },
  copyLabDbUuid: {
    value: '',
    error: undefined,
    placeHolder: 'Lab DB UUID',
    label: 'Lab DB UUID',
    rule: 'required',
  },
  copyBrandDbUuid: {
    value: '',
    error: undefined,
    placeHolder: 'Brand DB UUID',
    label: 'Brand DB UUID',
    rule: 'required',
  },
  // brandNotificationEmail: {
  //   value: '',
  //   error: undefined,
  //   label: 'Brand Notification Email',
  //   rule: 'optional|email',
  // },
  // labNotificationEmail: {
  //   value: '',
  //   error: undefined,
  //   label: 'Lab Notification Email',
  //   rule: 'optional|email',
  // },
}

// const PLUGIN_META = {
//   universalServiceId: {
//     value: '',
//     error: undefined,
//     placeHolder: 'Enter Universal Service ID',
//     label: 'UNIVERSAL SERVICE ID',
//     rule: 'optional',
//     objRef: 'pluginMeta.quest',
//     objRefOutput: 'pluginMeta.quest',
//   },
// }

export const NEW_PANEL = {
  status: {
    values: [
      { key: 'PUBLISHED', value: 'PUBLISHED', text: 'Live' },
      { key: 'DRAFT', value: 'DRAFT', text: 'Draft' },
      { key: 'REVIEW', value: 'REVIEW', text: 'Review' },
    ],
    error: undefined,
    label: 'STATUS',
    placeHolder: 'select',
    rule: 'required',
    objRef: 'status',
  },
  version: {
    values: [
      { key: 'V2', value: 'V2', text: 'V2' },
      { key: 'V1', value: 'V1', text: 'V1' },
    ],
    error: undefined,
    label: 'VERSION',
    placeHolder: 'select',
    rule: 'required',
    objRef: 'version',
  },
  type: {
    values: [
      { key: 'FULL_PANEL', value: 'FULL_PANEL', text: 'FULL PANEL' },
      { key: 'APPROVED_LIST', value: 'APPROVED_LIST', text: 'APPROVED LIST' },
    ],
    error: undefined,
    label: 'TYPE',
    placeHolder: 'select',
    rule: 'required',
    objRef: 'type',
  },
  name: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Panel Name',
    label: 'NAME',
    rule: 'required',
  },
  prefix: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Prefix',
    label: 'PREFIX',
    rule: 'required',
  },
  brandId: {
    value: '',
    error: undefined,
    label: 'BRAND',
    placeHolder: 'select',
    rule: 'required',
    objRef: 'brand.id',
  },
  labId: {
    value: '',
    error: undefined,
    label: 'LAB',
    placeHolder: 'select',
    rule: 'required',
    objRef: 'lab.id',
  },
  labApiIntegration: {
    value: '',
    error: undefined,
    label: 'Plugin',
    placeHolder: 'select',
    rule: 'optional',
    objRef: 'labApiIntegration'
  },
  gender: { ...GENDER, placeHolder: 'Select' },
  minAge: {
    value: '',
    error: undefined,
    label: 'AGE MIN',
    rule: 'required',
  },
  maxAge: {
    value: 'ANY',
    error: undefined,
    label: 'AGE MAX',
    rule: 'required',
  },
  brandNotificationEmail: {
    value: '',
    error: undefined,
    label: 'Brand Notification Email',
    rule: 'optional',
  },
  labNotificationEmail: {
    value: '',
    error: undefined,
    label: 'Lab Notification Email',
    rule: 'optional',
  },
  ineligibleStates: {
    value: '',
    error: undefined,
    label: 'Ineligible States',
    rule: 'optional',
  },
  ordrsLabPanel: {
    values: [{ label: 'ORDRS Lab Panel', value: false }],
    error: undefined,
    label: '',
    placeHolder: '',
    rule: 'optional'
  },
  ordrsLabPanelDropdown: {
    value: '',
    error: undefined,
    label: 'Pricing',
    rule: 'optional',
  },
  panelPrice: {
    value: '',
    error: undefined,
    label: 'Panel Price',
    rule: 'optional',
  },
  provisionalApprove: {
    value: false,
    error: undefined,
    placeHolder: 'Choose Provisional Approval Option',
    values: [
      { key: 'TRUE', text: 'TRUE', value: true },
      { key: 'FALSE', text: 'FALSE', value: false },
    ].map(g => ({ key: g.key, value: g.value, text: g.text })),
    label: 'PROVISIONAL APPROVAL',
    rule: 'required',
    objRef: 'config',
    objRefOutput: 'config',
  },
  expeditedRelease: {
    value: false,
    error: undefined,
    placeHolder: 'Choose Expedited Release Option',
    values: [
      { key: 'TRUE', text: 'TRUE', value: true },
      { key: 'FALSE', text: 'FALSE', value: false },
    ].map(g => ({ key: g.key, value: g.value, text: g.text })),
    label: 'EXPEDITED RELEASE',
    rule: 'required',
    objRef: 'config',
    objRefOutput: 'config',
  },
  isReportRequired: {
    value: false,
    error: undefined,
    placeHolder: 'Is Report Required',
    values: [
      { key: 'TRUE', text: 'TRUE', value: true },
      { key: 'FALSE', text: 'FALSE', value: false },
    ].map(g => ({ key: g.key, value: g.value, text: g.text })),
    label: 'REPORTS REQUIRED',
    rule: 'required',
    objRef: 'config',
    objRefOutput: 'config',
  },
  panelImage: {
    value: '',
    error: undefined,
    placeHolder: 'Panel Image',
    label: 'PANEL IMAGE',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
    ...FILE_UPLOAD,
  },
  panelDescriptions: {
    value: '',
    error: undefined,
    placeHolder: 'Panel Description',
    label: 'PANEL DESCRIPTION',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  panelName: {
    value: '',
    error: undefined,
    placeHolder: 'Panel Name',
    label: 'PANEL NAME',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  panelPrefix: {
    value: '',
    error: undefined,
    placeHolder: 'Panel Prefix',
    label: 'PANEL PREFIX',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  pdfOrder: {
    values: [
      { key: 'FIRST', value: 'FIRST', text: 'RESULT APPEAR FIRST' },
      { key: 'LAST', value: 'LAST', text: 'RESULT APPEAR LAST' },
    ],
    error: undefined,
    label: 'PDF ORDER',
    placeHolder: 'select',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  allowIncompleteResults: {
    values: [{ label: 'ALLOW INCOMPLETE RESULT', value: true }],
    error: undefined,
    label: '',
    placeHolder: '',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  allowCorrectedResults: {
    values: [{ label: 'ALLOW CORRECTED RESULTS', value: false }],
    error: undefined,
    label: '',
    placeHolder: '',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  allowDynamicRangeRef: {
    values: [{ label: 'ALLOW DYNAMIC RANGE REF', value: false }],
    error: undefined,
    label: '',
    placeHolder: '',
    rule: 'optional',
    objRef: 'meta',
    objRefOutput: 'meta',
  },
  contextDefaults: {
    value: '',
    error: undefined,
    defaultValue: "",
    label: 'CONTEXT DEFAULT',
    placeHolder: 'Add default Context',
    rule: 'optional|isValidJson',
  },
  panelRef: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Panel Reference',
    label: 'PANEL REFERENCE',
    rule: 'optional',
  },
  panelDesc: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Panel Description',
    label: 'PANEL DESCRIPTION',
    rule: 'optional',
  }
};

export const ADD_NEW_FORM_META = {
  title: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Form Title',
    label: 'Title',
    rule: 'optional',
  },
  note: {
    value: '',
    error: undefined,
    placeHolder: 'Enter note',
    label: 'Note',
    rule: 'optional',
  },
  hideHeader: {
    value: false,
    error: undefined,
    placeHolder: 'Header',
    values: [{ label: 'Hide Header', value: true }],
    rule: 'optional',
  },
}

export const ADD_NEW_QUESTION_META = {
  questionLabel: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Question Label',
    label: 'Question Label',
    rule: 'required',
  },
  inputKeyName: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Input Key Name',
    label: 'Input Key Name',
    rule: 'required',
  },
  defaultVal: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Default value',
    label: 'Default value',
    rule: 'optional',
  },
  keyValuePair: {
    value: '',
    error: undefined,
    placeHolder: 'Enter array of object',
    label: 'Array of key value object',
    rule: 'optional|isValidJson',
  },
  isMultiCheck: {
    value: '',
    error: undefined,
    values: [{ label: 'Is MultiCheck', value: true }],
    defaultValue: true,
    rule: 'optional',
  },
  addonProps: {
    value: '',
    defaultValue: '{ "isHideLabel": true }',
    error: undefined,
    placeHolder: 'Enter Object',
    label: 'Additional Props',
    rule: 'optional|isValidJson',
  },
  order: {
    value: '',
    error: undefined,
    placeHolder: 'Enter order',
    label: 'Order',
    rule: 'optional',
  },
  inputType: {
    values: [
      { key: 'INPUT', value: 'INPUT', text: 'Input' },
      { key: 'TEXTAREA', value: 'TEXTAREA', text: 'Textarea' },
      { key: 'DROPDOWN', value: 'DROPDOWN', text: 'Dropdown' },
      { key: 'CHECKBOX', value: 'CHECKBOX', text: 'Checkbox' },
      { key: 'RADIO', value: 'RADIO', text: 'Radio' },
      { key: 'DATE', value: 'DATE', text: 'Date' },
    ],
    error: undefined,
    label: 'Input Type',
    placeHolder: 'select',
    rule: 'required',
  },
  status: {
    values: [
      { key: 'PUBLISHED', value: 'PUBLISHED', text: 'Published' },
      { key: 'DRAFT', value: 'DRAFT', text: 'Draft' },
    ],
    error: undefined,
    label: 'Status',
    placeHolder: 'select',
    rule: 'required',
  },
  isRequired: {
    value: '',
    error: undefined,
    values: [{ label: 'Is Required', value: true }],
    defaultValue: true,
    rule: 'required',
  },
}

export const AUTO_GENERATE_KITID_META = {
  kitIdLength: {
    value: '',
    defaultValue: 8,
    error: undefined,
    placeHolder: 'Enter Length',
    label: 'Length',
    rule: 'optional',
  },
  kitIdPrefix: {
    value: '',
    defaultValue: '',
    error: undefined,
    placeHolder: 'Enter Prefix',
    label: 'Prefix',
    rule: 'optional',
  },
  kitIdPostfix: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Postfix',
    label: 'Postfix',
    rule: 'optional',
  },
  kitIdQuantity: {
    value: '',
    error: undefined,
    placeHolder: 'Enter Quantity',
    label: 'Quantity',
    rule: 'required',
  },
  alphaNumeric: {
    values: [{ label: 'Alphanumeric', value: false }],
    error: undefined,
    label: '',
    placeHolder: '',
    rule: 'optional',
  }
  
}

export { MARKER as MARKER_META } from './marker';

